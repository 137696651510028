import React from 'react';
import { Box, Heading, Paragraph } from 'grommet';
import HeroMainHome from '../components/HeroMainHome';
import ThreeUp from '../components/ThreeUp';
import CtaSection from '../components/CtaSection';
import HighlightListAlt from '../components/HighlightListAlt';

export default function({ doc, Link }) {
  if (!doc) return null;

  const { hero, gtmUp, highlights, threeUp, cta } = doc;

  return (
    <>
      <HeroMainHome Link={Link} {...hero} />
      <ThreeUp {...threeUp} />
      <HighlightListAlt Link={Link} {...highlights} />
      <Box background={'dark-1'} pad="large">
        <Box justify="center">
          <Heading level="2" style={{margin: '0 auto', textAlign: 'center'}}>Unlock greater confidence in your valuation analyses</Heading>
          <Paragraph style={{margin: '20px auto', textAlign: 'center', maxWidth: '800px'}}>Backed by 35 years of history, Investor Survey was designed to help you better support real estate valuation analyses by providing a holistic, timely view of the CRE landscape. You’ll find in-depth information related to capitalization and discount rates, and other key cash flow assumptions.</Paragraph>
       </Box>
      </Box>
      <CtaSection Link={Link} {...cta} />
    </>
  );
}
