import React, { useState } from 'react';
import { Box, Heading, Paragraph, ResponsiveContext } from 'grommet';
import CtaBanner from '../components/CtaBanner';
import styled from 'styled-components';
import HeroMain from '../components/HeroMain';
import GatsbyAnchor from '../components/GatsbyAnchor';

const FeatContainer = styled(Box)`
  background: white;
  border: solid 1px #D2D2D2;
  border-radius: 5px;
  // width: calc(calc(32% - 16px));
  padding: 36px 24px;
  text-align: center;
  margin-bottom: 20px;
  outline: none !important;
`;
const FeatContainerLink = styled(GatsbyAnchor)`
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
  outline: none !important;
  border: none !important;
  box-shadow: none;
`;

export default function({ doc, Link }) {
  const [stateTags, setTags] = useState({ 'All Content': true });
  if (!doc) return null;

  const { hero, categories, cta } = doc;
  const handleTag = (e, tagValue) => {
    const target = e.target;
    setTags((tags) => {
      return {
        ...tags,
        [tagValue]: target.checked
      }
    })
  };
  const pad = {
    small: 'large',
    hero: 'medium',
  };

  return (
    <>
      <HeroMain Link={Link} {...hero} />
      <ResponsiveContext.Consumer>
        {(size) => {
          const small = size === 'small';
          const hero = size === 'hero';

          return (
            <Box
              pad={{top: 'large'}}
            >
              <Heading
                level="2"
                alignSelf="center"
                textAlign="center"
              >
                Featured Resources
              </Heading>
              <Box 
                direction="row-responsive"
                justify="evenly"
                wrap 
                width={{
                  max: "1360px"
                }}
                margin="0 auto"
                pad={small || hero ? pad[size] : 'large'}
              >
                <FeatContainer basis={small ? 'auto' : 'calc(32% - 16px)'}>
                  <FeatContainerLink href={"https://assets.ctfassets.net/7vbpf4ov698h/4bCsmZVME2FmMcLnPO8Qmo/6bcdc1967cab2661d889b74d2fa7beab/investors-finetuning-4q23.pdf"} target={"_blank"}>
                  <img width="62px" src="https://images.ctfassets.net/hql8txguu3eo/3RSIa9LHosUVqlcOvGopRK/0fe2a86f66aa6f7fe1691b3ef6fc5e18/tv-guide-icon.png" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >Investors finetuning strategies for 2024
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    >Investor Survey highlights from the fourth quarter of 2023</Paragraph>
                    <Paragraph
                      margin={{ top: '20px' }}
                      style={{
                          color: '#D04A02'
                      }}
                    ><u>Download eBook</u></Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
                <FeatContainer basis={small ? 'auto' : 'calc(32% - 16px)'}>
                  <FeatContainerLink href={"https://assets.ctfassets.net/7vbpf4ov698h/7J7EMWedenu9ICeyLGJ71L/ba285e42af6fcc68287fa8683ad21945/navigating-reshape-23highlights.pdf"} target={"_blank"}>
                  <img width="62px" src="https://images.ctfassets.net/hql8txguu3eo/3RSIa9LHosUVqlcOvGopRK/0fe2a86f66aa6f7fe1691b3ef6fc5e18/tv-guide-icon.png" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >Navigating a reshaped landscape
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    >Key Investor Survey 2023 highlights</Paragraph>
                    <Paragraph
                      margin={{ top: '20px' }}
                      style={{
                          color: '#D04A02'
                      }}
                    ><u>Download eBook</u></Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
                <FeatContainer basis={small ? 'auto' : 'calc(32% - 16px)'}>
                  <FeatContainerLink href={"https://assets.ctfassets.net/7vbpf4ov698h/1gDTZWVkYcbUl4eiqS8F1q/07ce845275360b265a9fb2074b54aa8f/searching-for-signals-3q23.pdf"} target={"_blank"}>
                  <img width="62px" src="https://images.ctfassets.net/hql8txguu3eo/3RSIa9LHosUVqlcOvGopRK/0fe2a86f66aa6f7fe1691b3ef6fc5e18/tv-guide-icon.png" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >Searching for signals in the noise
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    >Investor Survey highlights for the third quarter of 2023</Paragraph>
                    <Paragraph
                      margin={{ top: '20px' }}
                      style={{
                          color: '#D04A02'
                      }}
                    ><u>Download eBook</u></Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
                <FeatContainer basis={small ? 'auto' : 'calc(32% - 16px)'}>
                  <FeatContainerLink href={"https://www.youtube.com/watch?v=lrQy_b1vN0s"} target={"_blank"}>
                  <img width="62px" src="https://images.ctfassets.net/l07wyut35yzl/6tAG3DKNpLSNK76VsQvBfP/65a541d982fb400a4d99006160c7af97/io-webinar.svg" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >Webinar: Real estate trends for Q3 2023
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    >Watch this webinar for key trends for third quarter of 2023</Paragraph>
                    <Paragraph
                      margin={{ top: '20px' }}
                      style={{
                          color: '#D04A02'
                      }}
                    ><u>Watch webinar</u></Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
                <FeatContainer basis={small ? 'auto' : 'calc(32% - 16px)'}>
                  <FeatContainerLink href={"https://assets.ctfassets.net/7vbpf4ov698h/sBYWJyMU6AcRiv3AUSLsk/706a4ffdddcc605a46fa62b872f38d29/navigating-rising-cap-rates-2q23.pdf"} target={"_blank"}>
                  <img width="62px" src="https://images.ctfassets.net/hql8txguu3eo/3RSIa9LHosUVqlcOvGopRK/0fe2a86f66aa6f7fe1691b3ef6fc5e18/tv-guide-icon.png" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >Navigating rising cap rates
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    >Key takeaways about current CRE trends and Investor Survey highlights for second quarter of 2023</Paragraph>
                    <Paragraph
                      margin={{ top: '20px' }}
                      style={{
                          color: '#D04A02'
                      }}
                    ><u>Download eBook</u></Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
                <FeatContainer basis={small ? 'auto' : 'calc(32% - 16px)'}>
                  <FeatContainerLink href={"https://assets.ctfassets.net/7vbpf4ov698h/6JhEuqWuOeuJFj5Rjex0T/3832404bef33302f3d8a964ed5e65d18/making-sense.pdf"} target={"_blank"}>
                  <img width="62px" src="https://images.ctfassets.net/hql8txguu3eo/3RSIa9LHosUVqlcOvGopRK/0fe2a86f66aa6f7fe1691b3ef6fc5e18/tv-guide-icon.png" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >Making sense of an ever-changing market
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    >Explore key takeaways from the first quarter of 2023</Paragraph>
                    <Paragraph
                      margin={{ top: '20px' }}
                      style={{
                          color: '#D04A02'
                      }}
                    ><u>Download eBook</u></Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
                <FeatContainer basis={small ? 'auto' : 'calc(32% - 16px)'}>
                  <FeatContainerLink href={"https://assets.ctfassets.net/7vbpf4ov698h/2gUN8yXksRw3YpqpTurIQ9/addc90a5fe82a876528cb7321bb3c370/commercial-real-estate-uncertain.pdf"} target={"_blank"}>
                  <img width="62px" src="https://images.ctfassets.net/hql8txguu3eo/3RSIa9LHosUVqlcOvGopRK/0fe2a86f66aa6f7fe1691b3ef6fc5e18/tv-guide-icon.png" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >Investors weigh in on disruptive forces impacting the CRE landscape
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    >Investors pursue deals selectively, until the Fed provides clarity on interest rates</Paragraph>
                    <Paragraph
                      margin={{ top: '20px' }}
                      style={{
                          color: '#D04A02'
                      }}
                    ><u>Download eBook</u></Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
                <FeatContainer basis={small ? 'auto' : 'calc(32% - 16px)'}>
                  <FeatContainerLink href={"https://assets.ctfassets.net/7vbpf4ov698h/4DtF4diib4CFfpB6LtzjhR/db1d4e6780db2e25664a8abf4819cef5/investors-likely.pdf"} target={"_blank"}>
                  <img width="62px" src="https://images.ctfassets.net/hql8txguu3eo/3RSIa9LHosUVqlcOvGopRK/0fe2a86f66aa6f7fe1691b3ef6fc5e18/tv-guide-icon.png" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >Investors likely to hold assets in the year ahead
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    >Investor Survey highlights for Fourth Quarter 2022</Paragraph>
                    <Paragraph
                      margin={{ top: '20px' }}
                      style={{
                          color: '#D04A02'
                      }}
                    ><u>Download eBook</u></Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
                <FeatContainer basis={small ? 'auto' : 'calc(32% - 16px)'}>
                  <FeatContainerLink href={"https://assets.ctfassets.net/7vbpf4ov698h/5EJfcPWkzLsKZKK1R16V9d/b6e3fef2fc43f5d07d872b65037a9b68/rising-ebook.pdf"} target={"_blank"}>
                  <img width="62px" src="https://images.ctfassets.net/hql8txguu3eo/3RSIa9LHosUVqlcOvGopRK/0fe2a86f66aa6f7fe1691b3ef6fc5e18/tv-guide-icon.png" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >Rising capital costs inspire caution
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    >Key Investor Survey 2022 highlights</Paragraph>
                    <Paragraph
                      margin={{ top: '20px' }}
                      style={{
                          color: '#D04A02'
                      }}
                    ><u>Download eBook</u></Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
                <FeatContainer basis={small ? 'auto' : 'calc(32% - 16px)'}>
                  <FeatContainerLink href={"https://assets.ctfassets.net/7vbpf4ov698h/3kVd8ZwtP23NnmUAke5ASi/35eaa626f26419cf3b031b03cbdf1a2f/navigating-ebook.pdf"} target={"_blank"}>
                  <img width="62px" src="https://images.ctfassets.net/hql8txguu3eo/3RSIa9LHosUVqlcOvGopRK/0fe2a86f66aa6f7fe1691b3ef6fc5e18/tv-guide-icon.png" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >Navigating interest rate disruption
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    >How real-time data can facilitate better CRE decisions amid volatility</Paragraph>
                    <Paragraph
                      margin={{ top: '20px' }}
                      style={{
                          color: '#D04A02'
                      }}
                    ><u>Download eBook</u></Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
              </Box>
              
            </Box>
          );
        }}
      </ResponsiveContext.Consumer>
      {/* {categories &&
        categories.map((stories, index) => {
          const cardsFiltered = stories.cards.filter((card) => {
            const shouldShow = stateTags['All Content']
              ? true
              : card.tags === null
              ? false
              : card.tags &&
                card.tags.reduce((acc, curTag) => {
                  return acc || stateTags[curTag] || false;
                }, false);
            return shouldShow;
          });
          const storiesFiltered = { ...stories, cards: cardsFiltered };

          // console.log(cardsFiltered);
          const background = (index + 1) % 2 === 0 ? 'light-6' : 'white';
          return (
            <>
              <Box 
                pad={{top: 'large'}}
                width={{
                  max: "1360px"
                }}
                margin="0 auto"
              >
              <Heading
                margin={{ top: 'medium', bottom: 'medium' }}
                level="2"
                alignSelf="center"
                textAlign="center"
              >
                Webinars, eBooks, and Blog Posts
              </Heading>
              </Box>
  
              <Box 
                pad={{bottom: 'xlarge'}}
                direction="row-responsive"
                justify="evenly"
                width={{
                  max: "1360px"
                }}
                margin="0 auto"
              >
                <Box 
                  direction="row"
                  justify="between"
                >
                <StoryListAlt Link={Link} background={background} {...storiesFiltered} />
                </Box>
              </Box>
              {cta && cta.headline && <CtaBanner Link={Link} background={background} {...cta} />}
            </>
          );
        })
      } */}
    </>
  );
}
