import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import HighlightCardAlt from './HighlightCardAlt';
import SectionHeader from '../SectionHeader';

const HighlightContainer = styled(Box)`
  position: relative;
`;

export default function({ Link, background, cards, header, border }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const small = size === 'small';
        const hero = size === 'hero';
        return (
          <Box background={background} style={{paddingBottom: small ? "20px" : '80px'}}> 
            {header && <SectionHeader {...header} />}
            <HighlightContainer
              gap={small ? "medium" : "120px"}
              pad={{
                horizontal: small || hero ? 'none' : 'medium',
                top: header ? 'none' : 'xlarge',
                bottom: 'large',
              }}
              style={{
                maxWidth: '1360px',
                margin: '0 auto',
                width: '100%'
              }}
            >
              {cards &&
                cards.map((card, index) => (
                  <HighlightCardAlt
                    Link={Link}
                    reverse={(index + 1) % 2 !== 0}
                    {...card}
                    border={border}
                  />
                ))}
            </HighlightContainer>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
