import React from 'react';
import { Box, Heading, ResponsiveContext, ThemeContext } from 'grommet';
import RichText from '../RichText';

export default function({ children, headline, body, ...rest }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box
          align="center"
          pad={size === 'small' ? 'large' : 'xlarge'}
          style={{ textAlign: 'center' }}
          {...rest}
        >
          <ThemeContext.Extend
            value={{
              heading: { level: { '2': { medium: { maxWidth: '780px' } } } },
              paragraph: { medium: { maxWidth: '640px' } },
            }}
          >
            {headline && (
              <Heading level="2" margin="0 0 20px 0">
                {headline}
              </Heading>
            )}
            {body && <RichText body={body} />}

            {children}
          </ThemeContext.Extend>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
}
