import React from 'react';
import styled from 'styled-components';
import { Box, Heading, Text, ThemeContext } from 'grommet';
import GatsbyButton from '../GatsbyButton';
import RichText from '../RichText';

const Container = styled(Box)`
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.15);
`;

const FootnoteText = styled(Text)`
  font-style: italic;
`;

const PriceText = styled(Text)`
  line-height: 1.5;
  font-weight: bold;
  font-size: 52px;
  text-align: center
`;

const Onboarding = styled(Box)`
  text-align: center;
  & i {
    display: block
  }
`;

const CtaContainer = styled(Box)`
  text-align: center;
  min-height: 100px;

  & p:first-child {
    margin: 0;
  }

  & a {
    font-weight: bold;
  }
`;

const Features = styled.ul`
  flex: 1 1 auto;
  list-style: none;
  padding: 0;
  margin-top: 0;
`;

const Feature = styled.li`
  margin-bottom: 28px;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
  &:last-child {
    margin-bottom: 10px;
  }
  svg {
    position: absolute;
    left: 0px;
    top: 2px;
  }
`;

export default function({
  Link,
  features,
  featuresHeadline,
  fees,
  feesHeadline,
  first,
  headline,
  cardSummary,
  cardSummaryPlus,
  onboarding,
  prices,
  ctaText,
  primaryCta,
  secondaryCta
}) {
  return (
    
    <Container
      round={{ size: '10px' }}
      align="center"
      background={first ? 'white' : 'white'}
      pad={{horizontal:'large', vertical:'large'}}
      margin={{horizontal:'medium', vertical:'large'}}
      width={{
        // "min": "360px",
        "max": "420px"
      }}
      height={{
        "min":"840px"
      }}
    >
      <Box height={{ min: '60px'}}>
        <Heading level="3" margin={{ top: 'none', bottom: 'none' }} textAlign="center">
          {headline}
        </Heading>
      </Box>
      
      <Features
        id="features"
      >
        {featuresHeadline && (
          <Heading level="4" size="small" uppercase style={{textAlign: 'center', paddingBottom: '10px', fontSize: '18px'}}>
            {featuresHeadline}
          </Heading>
        )}

        {features.map((feature) => (
          <Feature>
            <svg width="26" height="20">
              <path
                fill="#FFB600"
                d="M 22.58,-0 L 9.23,13.22 3.42,7.46 0,10.85 9.23,20 26,3.39 22.58,-0 Z M 22.58,-0"
              />
            </svg><div dangerouslySetInnerHTML={{__html: feature}} />
          </Feature>
        ))}     
      </Features>

      {(ctaText || primaryCta || secondaryCta) && (
        <CtaContainer
          border={{
            color: 'dark-4',
            side: 'top',
          }}
          fill="horizontal"
          align="center"
          justify="center"
          pad={{ top: 'large' }}
        >
          {ctaText && <RichText Link={Link} body={ctaText} />}
          {(primaryCta || secondaryCta) && (
            <Box direction="row-responsive" fill="horizontal" justify="center" gap="medium">
              {primaryCta && (
                <Box basis="100%" pad={{ horizontal: 'medium' }}>
                  <ThemeContext.Extend value={{ button: { padding: { horizontal: '0' } } }}>
                    <GatsbyButton
                      Link={Link}
                      href={'https://www.simplecirc.com/subscribe/pwc-real-estate-investor-survey'}
                      label={primaryCta.buttonText}
                      color="accent-1"
                      primary
                      target="_blank"
                    />
                  </ThemeContext.Extend>
                </Box>
              )}
              {secondaryCta && (
                <Box basis="1/2">
                  <ThemeContext.Extend value={{ button: { padding: { horizontal: '0' } } }}>
                    <GatsbyButton
                      Link={Link}
                      href={'https://www.simplecirc.com/subscribe/pwc-real-estate-investor-survey'}
                      label={secondaryCta.buttonText}
                      width="medium"
                      color="accent-1"
                      target="_blank"
                    />
                  </ThemeContext.Extend>
                </Box>
              )}
            </Box>
          )}
        </CtaContainer>
      )}
    </Container>
  );
}
