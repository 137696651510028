import React from 'react';
import styled from 'styled-components';
import { Box, Button, Menu } from 'grommet';
import HeaderAltNavAnchor from './HeaderAltNavAnchor';
import GatsbyButton from '../GatsbyButton';

const DropdownBox = styled(Box)`
align-self: center;
font-weight: normal;

&:hover {
  background-color: none;
  var(--accent-2);
}
`;

const MenuContainer = styled(Menu)`
&:hover {
  background-color: none;
  color: var(--accent-2);
}
`;
export default function({ Link, loginLink, menuLinks, mobile, primaryLink, href, ...rest }) {
  return (
    <>
      <Box
        as="nav"
        alignSelf="stretch"
        direction={mobile ? 'column' : 'row'}
        pad={mobile ? 'large' : null}
        {...rest}
      >
        <HeaderAltNavAnchor
          Link={Link}
          activeClassName="active"
          href='/features'
          last={menuLinks.index >= menuLinks.length - 1}
          mobile={mobile}
          size={mobile ? 'large' : 'medium'}
          style={mobile ? '' : {marginLeft: '10px',marginRight: '30px'}}
          partiallyActive
        > Features
        </HeaderAltNavAnchor>
        <HeaderAltNavAnchor
          Link={Link}
          activeClassName="active"
          href='/whats-included'
          last={menuLinks.index >= menuLinks.length - 1}
          mobile={mobile}
          size={mobile ? 'large' : 'medium'}
          style={mobile ? '' : {marginLeft: '10px',marginRight: '30px'}}
          partiallyActive
        > What's included
        </HeaderAltNavAnchor>
        <HeaderAltNavAnchor
          Link={Link}
          activeClassName="active"
          href='/resources'
          last={menuLinks.index >= menuLinks.length - 1}
          mobile={mobile}
          size={mobile ? 'large' : 'medium'}
          partiallyActive
        > Resources
        </HeaderAltNavAnchor>
      </Box>
      
      <Box
        direction={mobile ? 'column' : 'row'}
        gap="small"
        pad={mobile ? { horizontal: 'medium', bottom: '120px' } : { vertical: 'small' }}
      >
        {loginLink && loginLink.name && loginLink.url && (
          <Button
            color="accent-2"
            label={loginLink.name}
            href={'https://www.pwc.com/us/investor-survey-contact'}
            target="_blank"
            id="nav-contact-button"
          />
        )}
        {primaryLink && primaryLink.name && primaryLink.url && (
          <GatsbyButton
            Link={Link}
            color="accent-2"
            label={primaryLink.name}
            href={'https://www.simplecirc.com/subscribe/pwc-real-estate-investor-survey'}
            primary
            id="nav-subscribe-button"
          />
        )}
      </Box>
    </>
  );
}
