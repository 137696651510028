import React from 'react';
import Img from 'gatsby-image';
import { Box, Heading, Image, ResponsiveContext } from 'grommet';
import GatsbyButton from '../GatsbyButton';
import YouTubeEmbed from '../VideoEmbed/YouTubeEmbed';
import RichText from '../RichText';

const textPad = {
  small: 'large',
  hero: 'medium',
};

const imgPad = {
  small: 'large',
  medium: 'large',
  large: 'large',
  hero: 'medium',
};

export default function({
  Link,
  body,
  slug,
  buttonText,
  buttonUrl,
  headline,
  icon,
  image,
  primary,
  reverse,
  video,
}) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const direction = !reverse ? 'row' : 'row-reverse';
        const small = size === 'small';
        const hero = size === 'hero';

        return (
          <Box id={slug} direction={small ? 'column' : direction} align={small ? 'start' : 'center'}>
            <Box
              basis={small ? 'auto' : '1/2'}
              fill="horizontal"
              justify="center"
              pad={{
                left: !reverse || small ? imgPad[size] : 'medium',
                right: !reverse && !small ? 'medium' : imgPad[size],
              }}
            >
              <Box>
                {video ? (
                  <YouTubeEmbed url={video} />
                ) : (
                  image && (
                    <Img fluid={image.localFile.childImageSharp.fluid} primary={primary} imgStyle={{border: '1px solid #464646', padding: '5px'}} style={{ padding: '5px', overflow: 'visible'}} />
                  )
                )}
              </Box>
            </Box>
            <Box
              basis={small ? 'auto' : '1/2'}
              pad={small || hero ? textPad[size] : 'large'}
              align="start"
              justify="start"
              flex
            >
              {icon && (
                <Box height="60px" width="60px" margin={{ bottom: 'medium' }}>
                  <Image src={icon} fit="contain" />
                </Box>
              )}
              {headline && (
                <Heading level='3' margin={{ bottom: 'small', right: 'medium ' }}>
                  {headline}
                </Heading>
              )}
              {body && <RichText body={body} />}
              {buttonUrl && buttonText && (
                <GatsbyButton
                  style={{marginTop: '20px'}} 
                  Link={Link}
                  href={buttonUrl}
                  label={buttonText}
                  minWidth="200px"
                  primary={primary}
                />
              )}
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
