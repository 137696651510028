import React from 'react';
import { Box, Heading, Image, ThemeContext } from 'grommet';
import RichText from '../RichText';

export default function({ body, image, headline, ...rest }) {
  return (
    <Box align="center" alignContent="center" style={{ textAlign: 'center' }} {...rest}>
      <Box height="80px" width="80px">
        {image && <Image src={image} fit="contain" />}
      </Box>
      {headline && (
        <Heading margin={{ bottom: 'none', top: '20px' }} level="3" style={{fontSize: '24px'}}>
          {headline}
        </Heading>
      )}
      {body && (
        <ThemeContext.Extend value={{ paragraph: { extend: () => `margin-top: 5px; max-width: 90%; ` } }}>
          <RichText body={body} />
        </ThemeContext.Extend>
      )}
    </Box>
  );
}
