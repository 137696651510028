import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import { Grommet } from 'grommet';

const GlobalStyle = createGlobalStyle`
  @font-face {
    display: swap;
    font-family: 'PwC ITC Charter';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/pwc-itc-charter-400.woff2') format('woff2'),
      url('/fonts/pwc-itc-charter-400.woff') format('woff');
  }
  @font-face {
    display: swap;
    font-family: 'PwC Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/pwc-helvetica-neue-400.woff2') format('woff2'),
      url('/fonts/pwc-helvetica-neue-400.woff') format('woff');
  }
  @font-face {
    display: swap;
    font-family: 'PwC Helvetica Neue';
    font-style: normal;
    font-weight: bold;
    src: url('/fonts/pwc-helvetica-neue-bold-500.woff2') format('woff2'),
      url('/fonts/pwc-helvetica-neue-bold-500.woff') format('woff');
  }
  @font-face {
    display: swap;
    font-family: 'PwC Helvetica Neue Light';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/pwc-helvetica-neue-light-300.woff2') format('woff2'),
      url('/fonts/pwc-helvetica-neue-light-300.woff') format('woff');
  }

  body {
    margin: 0;
  }
  .video-container iframe, .video-container object, .video-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
`;

function Layout({ children, theme }) {
  return (
    <>
      <GlobalStyle />
      {typeof window !== 'undefined' && (
        <Grommet theme={theme} userAgent={window.navigator.userAgent} cssVars>
          {children}
        </Grommet>
      )}
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
