import React from 'react';
import { Box, Heading, ResponsiveContext, ThemeContext } from 'grommet';
import Img from 'gatsby-image';
import styled from 'styled-components';
import RichText from '../RichText';
import HeroInteriorAltCta from './HeroInteriorAltCta';

const HeroOuterContainer = styled(Box)`
  display: block;
  position: relative;
`;

const HeroContainer = styled(Box)`
  min-height: 40vh;
  position: relative;
  border-bottom: solid 1px #d2d2d2
`;

const BgImg = styled(Img)`
  ${({ small }) => (small ? 'max-height: 30vh;' : 'position: absolute !important; z-index: 0;')}
  top: 0;
  width: ${({ small }) => (small ? '200%' : '100%')};
  bottom: 0;
  left: ${({ small }) => (small ? '-100%' : '0')};

  @media (max-height: 640px) {
    max-height: 100vh;
  }
`;

export default function({
  Link,
  background,
  headline,
  body,
  image,
  buttonText,
  buttonUrl,
  buttonHeadline,
  textBackground,
}) {
  return (
    <ThemeContext.Extend value={{ global: { breakpoints: { small: { value: 1024 } } } }}>
      <ResponsiveContext.Consumer>
        {(size) => {
          const small = ['small', 'hero'].includes(size);

          return (
            <HeroOuterContainer background={background} pad={small ? { bottom: '36px' } : { bottom: '72px' }}>
              <HeroContainer align="start" background="white" direction="row-responsive">
                {BgImg && (
                  <BgImg
                    small={small}
                    objectFit="cover"
                    objectPosition={small ? 'center right' : 'center bottom'}
                    imgStyle={{
                      right: 0,
                      left: 'auto',
                      objectFit: 'cover',
                      objectPosition: small ? 'center right' : 'center bottom',
                      width: small || textBackground ? '50%' : '100%',
                    }}
                    fluid={image.localFile.childImageSharp.fluid}
                  />
                )}
                <Box
                  align="start"
                  alignSelf="stretch"
                  basis="1/2"
                  justify="between"
                  background={textBackground}
                  pad={{ top: small ? 'large' : 'xlarge', horizontal: small ? 'large' : 'xlarge' }}
                  style={{ position: 'relative' }}
                >
                  <Box>
                    {headline && (
                      <Heading level="1" margin="0 0 20px 0">
                        {headline}
                      </Heading>
                    )}
                    {body && (
                      <ThemeContext.Extend
                        value={{
                          paragraph: { medium: { maxWidth: '460px' } },
                        }}
                      >
                        <RichText Link={Link} body={body} />
                      </ThemeContext.Extend>
                    )}
                  </Box>
                  {!small && (
                    <HeroInteriorAltCta
                      buttonHeadline={'Subscribe today'}
                      buttonUrl={'https://www.simplecirc.com/subscribe/pwc-real-estate-investor-survey'}
                      buttonText={'Subscribe'}
                      Link={Link}
                    />
                  )}
                </Box>
                {small && (
                  <HeroInteriorAltCta
                    buttonHeadline={'Subscribe today'}
                    buttonUrl={'https://www.simplecirc.com/subscribe/pwc-real-estate-investor-survey'}
                    buttonText={'Subscribe'}
                    Link={Link}
                  />
                )}
              </HeroContainer>
            </HeroOuterContainer>
          );
        }}
      </ResponsiveContext.Consumer>
    </ThemeContext.Extend>
  );
}
