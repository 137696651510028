import React from 'react';
import { Box, ResponsiveContext, Text} from 'grommet';
import SectionHeader from '../SectionHeader';
import PricingCardInvestorSurveyAlt from './PricingCardInvestorSurveyAlt';

export default function({ Link, background, headline, body, tiers, disclaimer }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const small = ['small', 'hero'].includes(size);

        return (
          <Box
            id="pricing"
            pad={
              small
                ? { horizontal: 'large', bottom: 'large' }
                : { horizontal: 'xlarge', bottom: 'xlarge' }
            }
            style={{ background: `url(${background}) top center` }}
          >
            {(headline || body) && <SectionHeader headline={headline} body={body} />}
            {tiers && (
              <Box
                direction={small ? 'column' : 'row'}
                // basis="1/2"
                wrap="true"
                justify="center"
                align="center"
                alignSelf="center"
                alignContent="center"
                margin={{ bottom: 'xlarge' }}
                width={{
                  "max": "1020px"
                }}
              >
                {tiers.map((tier, index) => (
                  <PricingCardInvestorSurveyAlt key={tier.id} Link={Link} {...tier} first={index === 1} />
                ))}
              </Box>
            )} 
            <Box 
              alignSelf="center"
              alignContent="center"
              direction="row"
              width={{
                "max": "880px"
              }}>
              <Text textAlign="center"><i>{disclaimer}</i></Text>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
