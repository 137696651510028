import React, { useState }from 'react';
import { Box, Button, Layer, Heading, Paragraph, ResponsiveContext, ThemeContext } from 'grommet';
import Img from 'gatsby-image';
import styled from 'styled-components';
import GatsbyButton from '../GatsbyButton';
import RichText from '../RichText';
import YouTubeEmbed from '../VideoEmbed/YouTubeEmbed';

const HeroContainer = styled(Box)`
  min-height: 64vh;
  position: relative;
  // max-width: 1560px;
  // margin: 0 auto;
`;

const BgImg = styled(Img)`
  ${({ small }) => (small ? 'max-height: 30vh;' : 'position: absolute !important; z-index: -1;')}
  top: 0;
  width: ${({ small }) => (small ? '200%' : '100%')};
  bottom: 0;
  left: ${({ small }) => (small ? '-100%' : '0')};

  @media (max-height: 640px) {
    max-height: 100vh;
  }
`;

export default function({ Link, headline, body, image, buttonText, buttonUrl, background }) {
  const [show, setShow] = React.useState();
  return (
    <ThemeContext.Extend value={{ global: { breakpoints: { small: { value: 1024} } } }}>
      <ResponsiveContext.Consumer>
        {(size) => {
          const small = ['small', 'hero'].includes(size);

          return (
            <HeroContainer align="center" direction="row-responsive">
              {BgImg && (
                <BgImg
                  small={small}
                  objectFit="cover"
                  objectPosition={small ? 'center left' : 'center center'}
                  imgStyle={{
                    right: 0,
                    left: 'auto',
                    objectFit: 'cover',
                    objectPosition: small ? 'center left' : 'center center',
                    width: small ? '50%' : '100%',
                  }}
                  fluid={image.localFile.childImageSharp.fluid}
                />
              )}
              <Box
                align="start"
                basis="2/3"
                pad={small ? 'large' : 'xlarge'}
                justify="start"
                background={small ? background : ''}
              >
                {headline && (
                  <Heading level="1" margin="0 0 20px 0" color={small ? '#000000' : '#FFFFFF'}>
                    {headline}
                  </Heading>
                )}
                {body && (
                  <ThemeContext.Extend
                    value={{
                      paragraph: { medium: { maxWidth: '640px'}}
                    }}
                  >
                    <Paragraph margin="0 0 30px 0" color={small ? '#000000' : '#FFFFFF'}>Investor Survey includes decades of commercial real estate (CRE) real estate survey data in a streamlined digital hub and goes beyond standard reporting with insights from active investors in the marketplace—allowing you to have greater confidence in your real estate analyses.</Paragraph>
                  </ThemeContext.Extend>
                )}
                {/* {buttonUrl && buttonText && (
                  <GatsbyButton Link={Link} href={buttonUrl} style={{marginTop: '10px'}} label={buttonText} primary />
                )} */}
                <Box>
                    <Button label="Watch video" onClick={() => setShow(true)} primary />
                    {show && (
                      <Layer
                        onEsc={() => setShow(false)}
                        onClickOutside={() => setShow(false)}
                        style={{width: '100%'}}
                      > <Box style={{maxWidth:'80%', width: '100%', margin: '20px auto'}}>
                        <YouTubeEmbed url={'https://www.youtube.com/embed/jE2fTJh-Msc'} />
                        <Button label="Close" onClick={() => setShow(false)} style={{padding: '10px 40px',maxWidth: '200px', margin: '20px auto'}} />
                        </Box>
                      </Layer>
                    )}
                  </Box>
              </Box>
            </HeroContainer>
          );
        }}
      </ResponsiveContext.Consumer>
    </ThemeContext.Extend>
  );
}
