import React from 'react';
import { Box, Heading, Image, ResponsiveContext } from 'grommet';
import GatsbyButton from '../GatsbyButton';
import RichText from '../RichText';

export default function({
  Link,
  headline,
  body,
  buttonText,
  buttonUrl,
  image,
  imageLarge,
  imageTop,
  reverse,
}) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const direction = !reverse ? 'row' : 'row-reverse';
        const small = size === 'small';
        const imageBasis = imageLarge ? '3/4' : '1/3';
        const hide = small && !imageLarge && !imageTop;

        return (
          <Box
            direction={small ? `column${!imageTop ? '-reverse' : ''}` : direction}
            align={small ? 'start' : 'center'}
          >
            {!hide && (
              <Box
                flex
                basis={small ? 'auto' : imageBasis}
                fill="horizontal"
                justify={imageLarge ? 'end' : 'center'}
                align={reverse ? 'end' : 'start'}
                alignSelf={imageLarge ? 'end' : 'center'}
                pad={
                  imageLarge || (small && imageTop)
                    ? 'none'
                    : {
                        vertical: 'medium',
                        left: !reverse || small ? 'none' : 'xlarge',
                        right: !reverse && !small ? 'xlarge' : 'none',
                      }
                }
              >
                {image && <Image width="100%" src={image} />}
              </Box>
            )}
            <Box
              align="start"
              basis={small ? 'auto' : '1/2'}
              justify="start"
              pad={small ? { horizontal: 'large', vertical: 'xlarge' } : 'xlarge'}
              flex
            >
              {headline && (
                <Heading level="2" margin="0 0 10px 0">
                  {headline}
                </Heading>
              )}
              {body && <RichText body={body} />}
              {buttonUrl && buttonText && (
                <GatsbyButton
                  style={{marginTop: '10px'}}
                  Link={Link}
                  href={'https://www.pwc.com/us/investor-survey-contact'}
                  label={buttonText}
                  minWidth="200px"
                  primary
                />
              )}
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
