const currency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export function forceSlashes(str) {
  const url = !str.startsWith('http') && !str.startsWith('/') ? `/${str}` : str;
  return !url.endsWith('/') && !url.includes('#') && !url.includes('?') ? `${str}/` : str;
}

export function formatPrice(num) {
  return currency.format(num);
}

export function isAnchor(str) {
  return str.includes('#');
}

export function isExternal(str) {
  return str.startsWith('http');
}

export function linkResolver({ slug, type, postPrefix }) {
  if (isExternal(slug)) return slug;
  if (slug === 'home') return '/';

  switch (type) {
    case 'ContentfulHomePage':
    case 'homePage':
      return '/';
    case 'ContentfulForm':
    case 'form':
      return `/forms/${slug}`;
    case 'ContentfulPost':
    case 'post':
      return `/${postPrefix}/${slug}`;
    case 'ContentfulGetStartedPage':
    case 'getstarted':
      return `/${slug}`;
    default:
      return slug;
  }
}


const firstUtm = (utm_medium, utm_source, utm_campaign, utm_offer, utm_term, cid) => {
  if(utm_medium && !localStorage.first_utm_medium) {
    localStorage.setItem('first_utm_medium', utm_medium)
    localStorage.setItem('first_utm_medium_timestamp', new Date())
  }
  if(utm_source && !localStorage.first_utm_source) {
    localStorage.setItem('first_utm_source', utm_source)
    localStorage.setItem('first_utm_source_timestamp', new Date())
  }
  if(utm_campaign && !localStorage.first_utm_campaign) {
    localStorage.setItem('first_utm_campaign', utm_campaign)
    localStorage.setItem('first_utm_campaign_timestamp', new Date())
  }
  if(utm_offer && !localStorage.first_utm_offer) {
    localStorage.setItem('first_utm_offer', utm_offer)
    localStorage.setItem('first_utm_offer_timestamp', new Date())
  }
  if(utm_term && !localStorage.first_utm_term) {
    localStorage.setItem('first_utm_term', utm_term)
    localStorage.setItem('first_utm_term_timestamp', new Date())
  }
  if(cid && !localStorage.first_cid) {
    localStorage.setItem('first_cid', cid)
    localStorage.setItem('first_cid_timestamp', new Date())
  }
}

export const getParams = () => {
  let params = new URLSearchParams(document.location.search.substring(1));
  let utm_medium = params.get("utm_medium");
  let utm_source = params.get("utm_source");
  let utm_campaign = params.get("utm_campaign");
  let utm_offer = params.get("utm_offer");
  let utm_term = params.get("utm_term");
  let cid = params.get("cid");

  firstUtm(utm_medium, utm_source, utm_campaign, utm_offer, cid);

  if(utm_medium) {
    localStorage.setItem('utm_medium', utm_medium)
    localStorage.setItem('utm_medium_timestamp', new Date())
  }

  if(utm_source) {
    localStorage.setItem('utm_source', utm_source)
    localStorage.setItem('utm_source_timestamp', new Date())
  }

  if(utm_campaign) {
    localStorage.setItem('utm_campaign', utm_campaign)
    localStorage.setItem('utm_campaign_timestamp', new Date())
  }

  if(utm_offer) {
    localStorage.setItem('utm_offer', utm_offer)
    localStorage.setItem('utm_offer_timestamp', new Date())
  }

  if(utm_term) {
    localStorage.setItem('utm_term', utm_term)
    localStorage.setItem('utm_term_timestamp', new Date())
  }
  
  if(cid) {
    localStorage.setItem('cid', cid)
    localStorage.setItem('cid_timestamp', new Date())
  }
}

export function queryString(obj) {
  return Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join('&');
}

export const isObject = item =>
  item && typeof item === 'object' && !Array.isArray(item);

export const deepFreeze = obj => {
  Object.keys(obj).forEach(
    key => key && isObject(obj[key]) && Object.freeze(obj[key]),
  );
  return Object.freeze(obj);
};

export const deepMerge = (target, ...sources) => {
  if (!sources.length) {
    return target;
  }
  // making sure to not change target (immutable)
  const output = { ...target };
  sources.forEach(source => {
    if (isObject(source)) {
      Object.keys(source).forEach(key => {
        if (isObject(source[key])) {
          if (!output[key]) {
            output[key] = { ...source[key] };
          } else {
            output[key] = deepMerge(output[key], source[key]);
          }
        } else {
          output[key] = source[key];
        }
      });
    }
  });
  return output;
};

export const removeUndefined = obj => {
  const result = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] !== undefined) {
      result[key] = obj[key];
    }
  });
  return result;
};