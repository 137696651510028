import React from 'react';
import { Box, Heading, ResponsiveContext, ThemeContext } from 'grommet';
import Img from 'gatsby-image';
import styled from 'styled-components';
import GatsbyButton from '../GatsbyButton';
import RichText from '../RichText';

const HeroContainer = styled(Box)`
  min-height: 50vh;
  position: relative;
`;

const TextContainer = styled(Box)`
  order: ${({ small }) => (small ? '2' : '1')};
`;

const ImageContainer = styled(Box)`
  position: relative;
  pointer-events: none;
  ${({ small }) => (small ? 'max-height: 50vh;' : '')}
  order: ${({ small }) => (small ? '1' : '2')};

  @media (max-height: 640px) {
    max-height: 100vh;
  }
`;

const CtaContainer = styled(Box)`
  ${({ small }) =>
    small ? '' : 'position: absolute; bottom: 0; right: 0; margin: 0 72px -72px 0;'}
  order: 3;
`;

const BgImg = styled(Img)`
  ${({ small }) => (small ? '' : 'position: absolute !important; z-index: -1;')}
  top: 0;
  width: 100%;
  bottom: 0;
  left: 0;
`;

export default function({
  Link,
  background,
  ctaBackground,
  headline,
  body,
  image,
  buttonText,
  buttonUrl,
  buttonHeadline,
}) {
  return (
    <ThemeContext.Extend value={{ global: { breakpoints: { small: { value: 1024 } } } }}>
      <ResponsiveContext.Consumer>
        {(size) => {
          const small = ['small', 'hero'].includes(size);

          return (
            <HeroContainer
              align="center"
              direction="row-responsive"
              margin={{ bottom: small ? 'none' : '72px' }}
            >
              <TextContainer
                alignSelf="stretch"
                background={background || 'brand'}
                basis="1/2"
                justify="center"
                pad={small ? 'large' : 'xlarge'}
                small={small}
              >
                {headline && (
                  <Heading level="1" margin="none">
                    {headline}
                  </Heading>
                )}
                {body && (
                  <ThemeContext.Extend
                    value={{
                      paragraph: { medium: { maxWidth: '480px' } },
                    }}
                  >
                    <RichText Link={Link} body={body} />
                  </ThemeContext.Extend>
                )}
              </TextContainer>
              <ImageContainer
                align="end"
                alignSelf="stretch"
                basis={small ? 'auto' : '1/2'}
                justify="end"
                small={small}
              >
                {BgImg && (
                  <BgImg
                    imgStyle={{
                      right: 0,
                      left: 'auto',
                      objectFit: 'cover',
                      objectPosition: 'center right',
                    }}
                    fluid={image.localFile.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="center right"
                    small={small}
                  />
                )}
              </ImageContainer>
              <CtaContainer
                background={ctaBackground || 'dark-1'}
                pad="large"
                small={small}
                width={small ? '100%' : 'medium'}
              >
                {buttonHeadline && (
                  <Heading level="4" margin={{ top: 'none' }}>
                    {buttonHeadline}
                  </Heading>
                )}
                {buttonUrl && buttonText && (
                  <GatsbyButton
                    Link={Link}
                    color="hero-interior-button"
                    href={buttonUrl}
                    label={buttonText}
                    primary
                  />
                )}
              </CtaContainer>
            </HeroContainer>
          );
        }}
      </ResponsiveContext.Consumer>
    </ThemeContext.Extend>
  );
}
